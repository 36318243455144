import request1 from '../util/loginrequest'
import request from '../util/request'
// 不在订阅
export const getCancel_email = (params) => {
  return request({
    url: '/api/demo/hsj/cancel_email',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}
// 邮箱推送
export const getEmail = (params) => {
  return request({
    url: '/api/demo/hsj/email',
    method: 'post',
    data: {
      email: params.email || '',
      user_id: params.user_id || ''
    }
  })
}
// 关键词是否被订阅
export const keywordsJudge = (params) => {
  return request({
    url: '/api/demo/subscribe/keywordsJudge',
    method: 'post',
    data: {
      keywords: params.keywords || '',
      user_id: params.user_id || ''
    }
  })
}
// 机构订阅删除
export const organizationDelete = (params) => {
  return request({
    url: '/api/demo/subscribe/organization_delete',
    method: 'post',
    data: {
      id: params.id || ''
    }
  })
}
// 用户信息
export const organizationluser = (params) => {
  return request({
    url: '/api/demo/hsj/user',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}
// 完善提交
export const getHsjperfect = (params) => {
  return request({
    url: '/api/demo/hsj/perfect',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      job: params.job || '',
      discipline_id: params.discipline_id || '',
      key_word1: params.key_word1 || '',
      key_word2: params.key_word2 || '',
      key_word3: params.key_word3 || ''
    }
  })
}
// 一级科学
export const getHsjdiscipline = (params) => {
  return request({
    url: '/api/demo/hsj/discipline',
    method: 'post',
    data: {
      INDUSTRY_ID: params.INDUSTRY_ID || ''
    }
  })
}
// 机构订阅列表
export const organizationllist = (params) => {
  return request({
    url: '/api/demo/subscribe/organization_list',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}
// 机构订阅添加
export const organizationAdd = (params) => {
  return request({
    url: '/api/demo/subscribe/organization_add',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      organization_id: params.organization_id || ''
    }
  })
}
// 机构订阅添加 新
export const organizationAddNew = (params) => {
  return request({
    url: '/api/demo/subscribe/organization_add_new',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      organization_id: params.organization_id || ''
    }
  })
}
// 机构是否订阅
export const organizationJudge = (params) => {
  return request({
    url: '/api/demo/subscribe/organizationJudge',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      organization_id: params.organization_id || ''
    }
  })
}
// 订阅设置列表
export const listSet = (params) => {
  return request({
    url: '/api/demo/subscribe/listSet',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}
// 文章取消订阅
export const deleteArt = (params) => {
  return request({
    url: '/api/demo/subscribe/deleteArt',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      title_id: params.user_id || '',
      type: params.user_id || ''
    }
  })
}
// 文章取消订阅
export const addArt = (params) => {
  return request({
    url: '/api/demo/subscribe/addArt',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      title_id: params.user_id || '',
      type: params.user_id || ''
    }
  })
}
// 订阅删除
export const subscribedelete = (params) => {
  return request({
    url: '/api/demo/subscribe/delete',
    method: 'post',
    data: {
      id: params.id || ''
    }
  })
}
// 订阅修改
export const subscribeupdate = (params) => {
  return request({
    url: '/api/demo/subscribe/update',
    method: 'post',
    data: {
      id: params.id || '',
      keywords: params.keywords || '',
      type: params.type || '',
      loacal: params.loacal || '',
      research_field: params.research_field || '',
      PROJECT_TYPE: params.PROJECT_TYPE || ''
    }
  })
}
// 订阅添加
export const subscribeadd = (params) => {
  return request1({
    url: '/api/demo/subscribe/add',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      keywords: params.keywords || '',
      type: params.type || '',
      loacal: params.loacal || '',
      research_field: params.research_field || '',
      PROJECT_TYPE: params.PROJECT_TYPE || ''
    }
  })
}
// 订阅列表
export const subscribelist = (params) => {
  return request({
    url: '/api/demo/subscribe/list',
    method: 'post',
    data: {
      type: params.type || '',
      id: params.id || '',
      pro_id: params.pro_id || ''
    }
  })
}
// 获取收藏列表
export const collectList = (params) => {
  return request1({
    url: 'api/demo/subscribe/collectList',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      type: params.type || ''
    }
  })
}
// 获取招标地区
export const getSupportArea = () => {
  return request({
    url: '/api/demo/ProjectGov/pro',
    method: 'get'

  })
}
// 获取收藏列表
export const getorganization_list_count = (params) => {
  return request({
    url: '/api/demo/subscribe/organization_list_count',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}
export const checkSubscribeSend = (params) => {
  return request({
    url: '/api/demo/VipRestrict/checkSubscribeSend',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}
export const getSendDays = (params) => {
  return request({
    url: '/api/demo/VipRestrict/getSendDays',
    method: 'post',
    data: {
      type: params.type || ''
    }
  })
}

export const showStatus = (params) => {
  return request({
    url: '/api/demo/VipRestrict/showStatus',
    method: 'post',
    data: {
      user_id: params.user_id || ''
    }
  })
}

export const vipSend = (params) => {
  return request({
    url: '/api/demo/VipRestrict/vipSend',
    method: 'post',
    data: {
      user_id: params.user_id || '',
      send_code: params.send_code || ''
    }
  })
}

