<template>
  <div class="background">
		<div class="z_loading" v-if="loading">
		  <img src="../../../assets/loading.gif" alt="" />
			<div style="margin-top: 6px;">加载中...</div>
		</div>
    <div class="header">
      <div class="header-content typeArea">
        <div class="header-left">
          <div
            class="left-tag hovertohand"
            :class="{ textchecked: switchtab === 1 }"
            @click="changetag(1)"
          >
            <div class="text">我的订阅</div>
            <div class="henxian"></div>
          </div>
          <div
            class="left-tag hovertohand"
            @click="changetag(2)"
            :class="{ textchecked: switchtab === 2 }"
          >
            <div class="text">个性推荐</div>
            <div class="henxian"></div>
          </div>
          <!-- <div
            class="left-tag hovertohand"
            @click="changetag(3)"
            :class="{textchecked:switchtab ===3}"
          >
            <div class="text">招标</div>
            <div class="henxian"></div>
          </div> -->
        </div>
        <!-- <div class="header-right">
          <div class="right-tag">区域范围</div>
          <div class="right-tag xiala hovertohand" @click="changeAreaShow">
            <div class="tag-text">
              {{ countryischecked }}
            </div>
            <div class="triangle" :class="{ flip: isAreaShow }"></div>
          </div>
          <div class="right-tag">项目状态</div>
          <div class="right-tag hovertohand" @click="changeStatusShow">
            <div class="tag-text">
              {{ statusischecked }}
            </div>
            <div class="triangle" :class="{ flip: isStatusShow }"></div>
          </div>
          <div
            class="status-select"
            v-if="isStatusShow"
            @click="statustocheck($event)"
          >
            <div class="status-item" @click="statusItemClick(0)">全部</div> -->
            <!-- <div
              class="status-item"
              @click="statusItemClick(1)"
            >未开始</div> -->
            <!-- <div class="status-item" @click="statusItemClick(2)">进行中</div>
            <div class="status-item" @click="statusItemClick(3)">已截止</div>
          </div>
          <div class="area-select" v-if="isAreaShow">
            <div
              class="area-item"
              v-for="item in arealist"
              :key="item.id"
              @click="areaitemClick(item)"
            >
              {{ item.AREA_NAME }}
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="content typeArea">
      <div class="content-left">
        <!-- 完善信息 -->
        <div v-if="switchtab === 2">
        <div class="z_wanshan1" v-if="wanshan3">
          <div class="left">请完善相关信息，以便获得更多优质的推荐内容！</div>
          <div class="right">
            <el-button class="open" @click="z_open">完善信息</el-button>
            <img
              src="@/components/template/img/close.png"
              class="close"
              @click="z_close1"
            />
          </div>
        </div>
        </div>
        <div class="items">
          <!-- 招标指南订阅 -->
          <template v-if="switchtab == 1">
            <div
              class="content-item"
              v-for="(item, index) in subscribeTenderList.slice(0, 10)"
              :key="-index - 1"
            >
              <!-- <div class="item-tag zhaobiao">招标</div> -->
              <div class="item-content">
                <div class="content-title" @click="toNewTenderDetail(item)">
                  <!-- <div
                    v-if="
                      item.statusfordate == '已截止' &&
                      item.dateformateend !== '——'
                    "
                    class="item-tag jiezhi"
                  >
                    {{ item.statusfordate }}
                  </div>
                  <div
                    v-if="
                      item.statusfordate == '进行中' &&
                      item.dateformateend !== '——'
                    "
                    class="item-tag jinxing"
                  >
                    {{ item.statusfordate }}
                  </div> -->
                  <div
                    v-if="item.HITS>700 && item.times < 0"
                    class="item-tag re"
                  >
                  热
                  </div>
                  <div
                    v-if="item.times > 0"
                    class="item-tag zhi"
                  >
                  止
                  </div>
                  <div class="title-text">{{ item.PROJECT_NAME }}</div>
                </div>
                <div class="content-info">
                  <div class="info-item long">
                    <div class="item-describe">发布机构：</div>
                    <div class="item-info">
                      <span v-if="item.PROJECT_GOVERNMENT == null">——</span>
                      <span class="fbjg" v-else>
                        {{ item.PROJECT_GOVERNMENT }}</span
                      >
                    </div>
                  </div>
                  <div class="info-item">
                    <div class="item-describe">资助力度：</div>
                    <div class="item-info">
                      <span>{{ item.PROJECT_FUNDS }}</span>
                      <span v-if="item.PROJECT_FUNDS === null">——</span>
                      <span v-else>万元</span>
                    </div>
                  </div>
                  <div class="info-item">
                    <div class="item-describe">发布时间：</div>
                    <div class="item-info">{{ item.PROJECT_DATE.substring(0,10) }}</div>
                  </div>
                  <div class="info-item long">
                    <div class="item-describe">资助范围：</div>
                    <div class="item-info">
                      <span v-if="item.TENDER_PROVINCE">{{
                        item.TENDER_PROVINCE
                      }}</span>
                      <span v-else>——</span>
                    </div>
                  </div>
                  <div class="info-item">
                    <div class="item-describe">截止时间：</div>
                    <div class="item-info">{{ item.PROJECT_DATE_END.substring(0,10)}}</div>
                  </div>
                </div>
                <div class="content-tag">
                  <div
                    class="tag-item"
                    v-for="(ite, index) in item.tagsArray"
                    :key="index"
                    @mouseenter="tagmouseenter($event)"
                    @mouseleave="tagmouseleave($event)"
                  >
                    {{ ite }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="content-top nocollection"
              v-if="subscribeTenderList.length === 0"
              style="flex-direction: column;"
            >
              <img
                src="../../../assets/image/我的订阅_slices/未订阅.png"
                alt=""
                style="max-width: 88%"
              />
              <div style="font-size:20px;color:#282828;line-height:1.5;margin:110px 0;text-align: center;">
                您还有没订阅任何内容，请点击右上角<br>“添加关键词”按钮进行添加
              </div>
            </div>
          </template>

          <!-- 招标指南订阅结束 -->
          <!-- 指南订阅开始 个性推荐-->
          <template v-if="switchtab == 2">
            <div
              class="content-item"
              v-for="(item, index) in subscribeGuideList.slice(0, wanshan4)"
              :key="index"
            >
              <!-- <div class="item-tag keti">课题</div> -->
              <div class="item-content">
                <div
                  class="content-title"
                  @click="toReportingGuideDetail(item)"
                >
                <div
                    v-if="item.HITS>700 && item.times < 0"
                    class="item-tag re"
                  >
                  热
                  </div>
                  <div
                    v-if="item.times > 0"
                    class="item-tag zhi"
                  >
                  止
                  </div>
                  <!-- <div
                    v-if="
                      item.statusfordate == '进行中' &&
                      item.dateformateend !== '——'
                    "
                    class="item-tag jinxing"
                  >
                    {{ item.statusfordate }}
                  </div> -->
                  <div class="title-text">{{ item.PROJECT_NAME }}</div>
                </div>
                <div class="content-info">
                  <div class="info-item long">
                    <div class="item-describe">发布机构：</div>
                    <div class="item-info">
                      <span
                        class="spanover"
                        v-if="
                          item.PROJECT_GOVERNMENT == null ||
                          item.PROJECT_GOVERNMENT == ''
                        "
                        >——</span
                      >
                      <span class="fbjg">{{ item.PROJECT_GOVERNMENT }}</span>
                    </div>
                  </div>
                  <div class="info-item">
                    <div class="item-describe">资助力度：</div>
                    <div class="item-info">
                      <span
                        v-if="
                          item.PROJECT_FUNDS == '' || item.PROJECT_FUNDS == null
                        "
                        >——</span
                      ><span v-else>{{ item.PROJECT_FUNDS }}万元</span>
                    </div>
                  </div>
                  <div class="info-item">
                    <div class="item-describe">发布时间：</div>
                    <div class="item-info">{{ item.PROJECT_DATE.substring(0,10) }}</div>
                  </div>
                  <div class="info-item long">
                    <div class="item-describe">资助范围：</div>
                    <div class="item-info">
                      <span v-if="item.PROJECT_GOVERNMENT_PRO !== null">{{
                        item.PROJECT_GOVERNMENT_PRO
                      }}</span>
                      <span v-else>——</span>
                    </div>
                  </div>
                  <div class="info-item">
                    <div class="item-describe">截止时间：</div>
                    <div class="item-info">{{ item.PROJECT_DATE_END.substring(0,10) }}</div>
                  </div>
                </div>
                <div class="content-tag">
                  <div
                    class="tag-item"
                    v-for="(ite, index) in item.tagsArray"
                    :key="index"
                    @mouseenter="tagmouseenter($event)"
                    @mouseleave="tagmouseleave($event)"
                  >
                    {{ ite }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="content-top nocollection"
              v-if="subscribeGuideList.length === 0"
              style="flex-direction: column;"
            >
              <img
                src="../../../assets/image/我的订阅_slices/未订阅.png"
                alt=""
                style="max-width: 88%"
              />
              <div style="font-size:20px;color:#282828;line-height:1.5;margin:110px 0;text-align: center;">
                您还有没订阅任何内容，请点击右上角<br>“添加关键词”按钮进行添加
              </div>
            </div>
          </template>

        </div>
        <div class="spacing"></div>
        <div v-if="switchtab === 2">
        <div class="z_wanshan2" v-if="wanshan">
          您的信息不全，无法为您推荐更多内容
          <div class="a" @click="z_open">点此完善>></div>
        </div>
        </div>
        <!-- <div>
          <template v-if="isshowall">
            <div class="paging" v-if="pageTotal > 1">
              <ul class="pagination"> -->
                <!-- <li>
          <span>共<span>{{pageTotal}}</span>页</span>
        </li> -->
                <!-- <li @click="homePage()">
          <a> 首页</a>
        </li> -->
                <!-- <li @click="prePage()" :class="pageNo <= 1 ? 'disabled' : ''">
                  <a>&lt; </a>
                </li>
                <li
                  v-for="(item, index) in pages"
                  :class="pageNo == item ? 'active' : ''"
                  :key="index"
                  @click="curPage(item)"
                >
                  <a href="javascript:void(0)">{{ item }}</a>
                </li>
                <li
                  @click="nextPage()"
                  :class="pageNo >= pageTotal ? 'disabled' : ''"
                >
                  <a> &gt; </a>
                </li> -->
                <!-- <li @click="endPage()">
          <a>末页 </a>
        </li> -->
                <!-- <div
          class="opt-jump"
          style="display: inline-block;"
        >
          <span
            @click="pageJump"
            style="cursor: pointer;"
          >跳转</span>
          <input
            type="text"
            v-model="jumpInput"
          />
        </div> -->
              <!-- </ul>
            </div>
          </template>
        </div> -->

        <!-- 指南分页 -->
        <template v-if="!wanshan3 && switchtab == 2">
          <div class="paging" v-if="pageTotalzn > 0">
            <ul class="pagination">
              <!-- <li>
          <span>共<span>{{pageTotal}}</span>页</span>
        </li> -->
              <!-- <li @click="homePage()">
          <a> 首页</a>
        </li> -->
              <li @click="prePagezn()" :class="pageNozn <= 1 ? 'disabled' : ''">
                <a>&lt; </a>
              </li>
              <li
                v-for="(item, index) in pagezns"
                :class="pageNozn == item ? 'active' : ''"
                :key="index"
                @click="curPagezn(item)"
              >
                <a href="javascript:void(0)">{{ item }}</a>
              </li>
              <li
                @click="nextPagezn()"
                :class="pageNozn >= pageTotalzn ? 'disabled' : ''"
              >
                <a> &gt; </a>
              </li>
              <!-- <li @click="endPage()">
          <a>末页 </a>
        </li> -->
              <!-- <div
          class="opt-jump"
          style="display: inline-block;"
        >
          <span
            @click="pageJump"
            style="cursor: pointer;"
          >跳转</span>
          <input
            type="text"
            v-model="jumpInput"
          />
        </div> -->
            </ul>
          </div>
        </template>
        <!-- 招标分页 -->
        <template v-if="isshowall">
          <div class="paging" v-if="pageTotalzb > 0">
            <ul class="pagination">
              <!-- <li>
          <span>共<span>{{pageTotal}}</span>页</span>
        </li> -->
              <!-- <li @click="homePage()">
          <a> 首页</a>
        </li> -->
              <li @click="prePagezb()" :class="pageNozb <= 1 ? 'disabled' : ''">
                <a>&lt; </a>
              </li>
              <li
                v-for="(item, index) in pagezbs"
                :class="pageNozb == item ? 'active' : ''"
                :key="index"
                @click="curPagezb(item)"
              >
                <a href="javascript:void(0)">{{ item }}</a>
              </li>
              <li
                @click="nextPagezb()"
                :class="pageNozb >= pageTotalzb ? 'disabled' : ''"
              >
                <a> &gt; </a>
              </li>
              <!-- <li @click="endPage()">
          <a>末页 </a>
        </li> -->
              <!-- <div
          class="opt-jump"
          style="display: inline-block;"
        >
          <span
            @click="pageJump"
            style="cursor: pointer;"
          >跳转</span>
          <input
            type="text"
            v-model="jumpInput"
          />
        </div> -->
            </ul>
          </div>
        </template>
      </div>

      <div class="content-right" v-if="switchtab === 1">
        <div class="right-item">
          <div class="item-title">
            <div class="left">
              <div class="shuxian"></div>
              <div class="text">我订阅的关键词</div>
            </div>
            <div class="right">还可订阅{{ z_keywords }}个</div>
          </div>
          <div class="item-content">
            <template v-for="(item, index) in mySubscribeTags">
              <div
                class="tag-item"
                :class="{ 'tag-no': item.time_out == 2 }"
                v-if="item.keywords !== null"
                :key="index"
                @mouseenter="subscribetagmouseenter($event, item)"
                @mouseleave="subscribetagmouseleave($event, item)"
              >
                <div class="text">{{ item.keywords }}</div>
                <div class="x" @click="removesubscribetags($event, item.id)">
                  x
                </div>
              </div>
            </template>
          </div>
          <div class="addtag" @click="addthetad" v-if="addtagisshow">
            <!-- <div class="jiahao">+</div> -->
            <div class="addtag-text">添加关键词</div>
          </div>
          <div class="theinput" v-else>
            <div class="theinput-left">
              <input type="text" v-model="addtaginput" />
              <!-- <el-dropdown>
                <el-button type="primary">
                  {{ clsscheckedtext }}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </el-button>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item
                    v-for="item in classlistfrotab"
                    :key="item.id"
                    @click.native="classchecked(item)"
                    >{{ item.name }}</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
            <div class="theinput-right">
              <div class="confirm" @click="confirmaddtag">确定</div>
              <div class="confirm quxiao" @click="quxiaoaddtag">取消</div>
            </div>
          </div>
          <div class="item-prompt">
            <div class="prompt-icon">
              <img
                src="../../../assets/image/我的订阅_slices/惊叹号.png"
                alt=""
              />
            </div>
            <div class="prompt-text">标签越丰富，推送的信息越精准</div>
          </div>
        </div>
        <div class="right-item">
          <div class="item-title">
            <div class="left">
              <div class="shuxian"></div>
              <div class="text">我订阅的机构</div>
            </div>
            <div class="right">还可订阅{{ z_keywords1 }}个</div>
          </div>
          <div
            class="item-jigou"
            v-for="(item, index) in myorganizationllist"
            :key="index"
            @mouseenter="myorganizationenter($event, item)"
            @mouseleave="myorganizationleave($event, item)"
          >
            <div class="jigou-tag" :class="{ 'tag-no': item.time_out == 2 }">
              <div class="tag-text">
                {{ item.organization }}
              </div>
              <div class="x" @click="delmyorganizationleav($event, item.id)">
                x
              </div>
            </div>
          </div>
          <div class="z_tuijian" v-if="z_tuijian1">
            <div class="title">您还没有订阅机构，我们为您推荐一批：</div>
            <ul>
              <el-checkbox-group v-model="z_tuijianlists" >
                <!-- {{ item.title }}
                <el-radio v-model="z_organization_id" :label="item.id"
                  >&nbsp;</el-radio
                > -->
                <el-checkbox v-for="(item, index) in z_tuijianlist" :key="index" :label=" item.title " @change="z_tuijian4(item,$event,index)"></el-checkbox>
              </el-checkbox-group>
            </ul>
            <el-button class="anniu" @click="z_tuijian3">确定</el-button>
          </div>
          <div class="item-prompt" v-if="z_tuijian2">
            <div class="prompt-icon">
              <img
                src="../../../assets/image/我的订阅_slices/惊叹号.png"
                alt=""
              />
            </div>
            <div class="prompt-text">在课题详情页可对机构进行订阅</div>
          </div>
        </div>
        <!-- <div class="right-item">
          <div class="item-title">
            <div class="shuxian"></div>
            <div class="text">
              推荐订阅
            </div>
          </div>
          <div class="item-content">
            <div
              class="tag-item"
              v-for="(item,index) in recommendSubscription"
              :key="index"
              @mouseenter="recommendSubscriptionenter($event)"
              @mouseleave="recommendSubscriptionleave($event)"
            >
              <div class="text">{{item}}</div>
              <div
                class="tianjia"
                @click="recommendSubscriptionclick"
              >
                +
              </div>
            </div>

          </div>
        </div> -->
        <!-- <div class="right-item">
          <div class="prompts">
            <div class="prompts-left">
              <div class="prompt-icon">
                <img
                  src="../../../assets/image/我的订阅_slices/惊叹号.png"
                  alt=""
                />
              </div>
              <div class="prompt-text" @click="tomyCollectionarticles">
                我的收藏
              </div>
            </div>
            <div class="prompts-right">
              <div class="tag-num">
                {{ mySubscribeArticle.msg }}
              </div>
            </div>
          </div>
          <div class="prompts">
            <div class="prompts-left">
              <div class="prompt-icon">
                <img
                  src="../../../assets/image/我的订阅_slices/惊叹号.png"
                  alt=""
                />
              </div>
              <div class="prompt-text" @click="tomyRecentInformation">
                近期浏览过的信息
              </div>
            </div>
            <div class="prompts-right">
              <div class="tag-num">12</div>
            </div>
          </div>
        </div> -->
        <div class="right-item">
          <div class="item-title">
            <div class="left">
              <div class="shuxian"></div>
              <div class="text">邮箱推送</div>
            </div>
          </div>
          <div class="z_youxiang">
            填写您的邮箱地址，我们将每周向您推送您所订阅的最新课题申报信息。
          </div>
          <div class="z_yx_input">
            <img src="@/components/template/img/fp3.png" />
            <el-input v-model="z_email" placeholder="请输入内容"></el-input>
          </div>
          <div class="z_yx_anniu" v-if="youxiang != 1">
            <el-button class="anniu1" type="primary" @click="tuisong"
              >确定</el-button
            >
          </div>
          <div class="z_yx_anniu" v-if="youxiang == 1">
            <el-button class="anniu2" @click="tuisong">修改</el-button>
            <el-button class="anniu2" @click="tuisong1">不再订阅</el-button>
          </div>
        </div>
      </div>

      <div class="content-right" v-if="switchtab === 2">
        <div class="right-item">
          <div class="item-title">
            <div class="left">
              <div class="shuxian"></div>
              <div class="text">同行在看</div>
            </div>
          </div>
          <ul class="z_tonghang">
            <Li
              v-for="(item, index) in tonghang.slice(0, 5)"
              :key="index"
              @click="toReportingGuideDetail(item)"
            >
              <h1>{{ item.PROJECT_NAME }}</h1>
              <h2>{{ item.PROJECT_DATE_END }}</h2>
            </Li>
          </ul>
        </div>
      </div>

      <div class="z_wanshan" v-if="wanshan1">
        <div class="dt">
          <div class="left">
            <div class="title">完善信息</div>
            <div class="ul">
              <div class="li">
                <div class="font">您所在单位 <span>*</span></div>
                <el-input
                  v-model="danwei"
                  placeholder="请填写您所在单位"
                ></el-input>
              </div>
            </div>
            <div class="ul">
              <div class="li">
                <div class="font">您的研究专业 <span>*</span></div>
                <el-select
                  v-model="zhuanye1"
                  placeholder="点击选择学科门类"
                  @change="z_zhuanye1"
                >
                  <el-option
                    v-for="item in rmnav1"
                    :key="item.INDUSTRY_ID"
                    :label="item.INDUSTRY_NAME"
                    :value="item.INDUSTRY_ID"
                  >
                  </el-option>
                </el-select>
              </div>
              <div class="li">
                <div class="font"></div>
                 <el-select
                  v-model="zhuanye2"
                  placeholder="点击选择一级学科"
                  @change="z_zhuanye2"
                >
                  <el-option
                    v-for="item in rmnav2"
                    :key="item.discipline_id"
                    :label="item.discipline"
                    :value="item.discipline_id"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div v-if="subscribelistlen.length < 3" style="height: 0.2rem;display: flex;align-items: center;font-size: 0.14rem;color: #b9b9b9;font-weight: bold;">您感兴趣的关键词 <span style="color: #f00;">*</span></div>
            <div class="ul" v-if="subscribelistlen.length < 3">
              <div class="li" v-if="!subscribelistlen1">
                <div class="font"></div>
                <el-input
                  v-model="keywords1"
                  placeholder="请填写关键词"
                ></el-input>
              </div>
              <div class="li" v-if="!subscribelistlen2">
                <div class="font"></div>
                <el-input
                  v-model="keywords2"
                  placeholder="请填写关键词"
                ></el-input>
              </div>
              <div class="li" v-if="!subscribelistlen3">
                <div class="font"></div>
                <el-input
                  v-model="keywords3"
                  placeholder="请填写关键词"
                ></el-input>
              </div>
            </div>
            <div class="anniu">
              <el-button class="active" @click="z_tijiao">提交</el-button>
              <el-button @click="z_close">返回</el-button>
            </div>
          </div>
          <div class="right">
            <img src="@/components/template/img/wanshan.png" />
          </div>
        </div>
      </div>
    </div>

	<!-- vip弹窗 -->
	<div class="z_vip" v-if="viptc">
	  <div class="dt">
	    <a class="close" @click="z_close">+</a>
	    <img src="../../../assets/image/tc1.png" />
	    <div class="h1">
	      查看其余<span>{{ zongshu }}</span
	      >个项目信息需要VIP会员资格哦
	    </div>
	    <div class="h2">
	      您可以邀请好友免费获得VIP会员<br />资格，或直接付费秒速升级
	    </div>
	    <div class="anniu">
	      <a @click="vip"
	        ><img src="../../../assets/image/tc2.png" />
	        <h1>付费升级<span>低至0.27元/天</span></h1></a
	      >
	      <a @click="vip1"
	        ><img src="../../../assets/image/tc3.png" />免费升级</a
	      >
	    </div>
	  </div>
	</div>
    <el-dialog width="420px" style="background-color: transparent;" :visible.sync="showSendVipTip" :show-close="false">
      <!-- 自定义展示内容 -->
			<div class="contents bg-send">
				<img @click="onTipBoxClose()" class="close-tr" src="https://keyanpro.com/image/close.svg" />
				<img class="send-res1" src="https://keyanpro.com/image/send-res1.png" />
				<div class="send-title">订阅成功</div>
				<div class="send-desc">送您<span>{{ send_days }}天VIP</span>，尽情体验科研人的强大功能吧</div>
				<div class="btn-send" @click="onTipBoxClose()">知 道 了</div>
			</div>
			<!-- 自定义关闭按钮 -->
    </el-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {
  subscribelist,
  organizationllist,
  organizationDelete,
  listSet,
  relatedProject,
  subscribedelete,
  subscribeadd,
  getSupportArea,
  collectList,
  organizationluser,
  getHsjdiscipline,
  getHsjperfect,
  getEmail,
  getCancel_email,
  getorganization_list_count,
  organizationAddNew,
  checkSubscribeSend,
  getSendDays
} from "../../../api/mySubscription";
import { organizationAdd } from "../../../api/details/politicsInTheTenderDetails";
// eslint-disable-next-line no-unused-vars
import {
  formatDate,
  formatDate1,
  makePy,
  checkCh,
  mkRslt,
} from "../../../util/tools";
// eslint-disable-next-line comma-spacing
// eslint-disable-next-line no-unused-vars
import {
  Addhistoricalrecordzc,
  Addhistoricalrecord,
  Addhistoricalrecordky,
  Addhistoricalrecordwz,
} from "../../../api/myRecentInformation";
import { getHsjindustry_new } from "../../../api/homePage";
export default {
  data() {
    return {
      // 赠送天数
      send_days: null,
      // 显示赠送vip的弹窗
      showSendVipTip: false,
      //完善信息
      danwei: "",
      zhuanye1: "",
      zhuanye2: "",
      zhuanye21: "",
      zhuanye3: "",
      keywords1: "",
      keywords2: "",
      keywords3: "",
      rmnav1: [],
      rmnav2: [],
      // 控制分页
      // 总页数
      pageTotal: -1,
      // 指南
      pageTotalzn: -1,
      // 招标
      pageTotalzb: -1,
      // 页码
      pageNo: 1,
      // 指南
      pageNozn: 1,
      // 招标
      pageNozb: 1,
      // 当前页
      page: 1,
      // 指南
      pagezn: -1,
      // 招标
      pagezb: -1,
      // 每一页的条数
      pageSize: 10,
      // 指南
      pageSizezn: -1,
      // 招标
      pageSizezb: -1,
      // 总条数
      rows: 1,
      // 指南
      rowszn: -1,
      // 招标
      rowszb: -1,
      // 跳转input输入框的值
      jumpInput: "",

      // 控制tab切换
      switchtab: 1,
      // 是否显示指南
      isshowsubscribeGuide: false,
      // 是否显示招标
      isshowsubscribeTender: false,
      isshowall: true,

      // 区域范围展开收起
      isAreaShow: false,
      // 项目状态的展开收起
      isStatusShow: false,

      // 订阅列表
      // 全部
      subscribeAllList: [],
      subscribeAllListbeifen: [],
      // 指南
      subscribeGuideList: [],
      // 指南备份
      subscribeGuideListbeifen: [],
      // 招标
      subscribeTenderList: [],
      // 招标备份
      subscribeTenderListbeifen: [],
      // 我订阅的机构列表
      myorganizationllist: [],
      // 我订阅的标签
      mySubscribeTags: [],
      z_keywords: "0",
      z_keywords1: "0",
      // 推荐订阅
      recommendSubscription: [],
      // 我订阅的文章
      mySubscribeArticle: [],
      // 地区列表
      arealist: [],
      // 输入的标签内容
      addtaginput: "",
      // 控制新增标签按钮的显示与隐藏
      addtagisshow: true,
      // 已选状态值
      statusischecked: "全部",
      // 已选国家地区
      countryischecked: "全国",
      // 是否重新读取分页数量
      isreadfenyeall: true,
      // 指南
      isreadfenyezn: true,
      // 招标
      isreadfenyezb: true,
      // 添加标签类别下拉列表
      classlistfrotab: [
        {
          id: 1,
          name: "申报指南",
        },
        {
          id: 2,
          name: "政采招标",
        },
        // , {
        //   id: 3,
        //   name: '科研项目'
        // }
      ],
      // 标签类别提示文字
      clsscheckedtext: "请选择标签类别",
      // 已选择的标签类别
      classalreadychecked: "",
      wanshan: false,
      wanshan1: false,
      wanshan2: true,
      wanshan3: false,
      wanshan4:10,
      youxiang: "",
			viptc: false,
      z_email: "",
			loading:false,
      tonghang: [],
      z_tuijian1: false,
      z_tuijian2: true,
      z_organization_id: "1",
      zimu: "",
      z_tuijianlists:["国家自然科学基金委员会","中华人民共和国科学技术部","全国哲学社会科学工作办公室"],
      z_tuijianlist: [
        { title: "国家自然科学基金委员会", id: "1" },
        { title: "中华人民共和国科学技术部", id: "2" },
        { title: "全国哲学社会科学工作办公室", id: "44" },
      ],
      cheList:['1','2','44'],
      subscribelistlen:[]
    };
  },
  computed: {
    pages() {
      const start = this.pageNo;
      const end = this.pageTotal;
      if (end < 10) return end;
      if (start <= 5) {
        return [1, 2, 3, 4, 5, 6, "...", end];
      } else if (start > end - 5) {
        return [1, "...", end - 5, end - 4, end - 3, end - 2, end - 1, end];
      } else {
        return [
          1,
          "...",
          start - 3,
          start - 2,
          start - 1,
          start,
          start + 1,
          start + 2,
          "...",
          end,
        ];
      }
    },
    pagezns() {
      const start = this.pageNozn;
      const end = this.pageTotalzn;
      if (end < 10) return end;
      if (start <= 5) {
        return [1, 2, 3, 4, 5, 6, "...", end];
      } else if (start > end - 5) {
        return [1, "...", end - 5, end - 4, end - 3, end - 2, end - 1, end];
      } else {
        return [
          1,
          "...",
          start - 3,
          start - 2,
          start - 1,
          start,
          start + 1,
          start + 2,
          "...",
          end,
        ];
      }
    },
    pagezbs() {
      const start = this.pageNozb;
      const end = this.pageTotalzb;
	  if (this.$store.state.ispro == "false") {
	      if (end < 10) return [1, 2, 3, 4, 5, 6, "..."];
	  }else{
		  if (end < 10) return end;
	  }
      if (start <= 5) {
        return [1, 2, 3, 4, 5, 6, "...", end];
      } else if (start > end - 5) {
        return [1, "...", end - 5, end - 4, end - 3, end - 2, end - 1, end];
      } else {
        return [
          1,
          "...",
          start - 3,
          start - 2,
          start - 1,
          start,
          start + 1,
          start + 2,
          "...",
          end,
        ];
      }
    },
  },
  created() {
    // 获取数据
    this.getsetswitchtab();
      this.getPageNo();
    this.getData();
    this.getPeerReview();
    this.navxuanzhong();

  },
  mounted(){
    window.scrollTo(0,0)
    // router.afterEach((to,from,next)=>{window,scrollTo(0,0)})
    getSendDays({type: 3}).then(res=> {
      this.send_days = res.vip_day;
    })
  },
  watch: {
    subscribeAllList: function (newVal, oldVal) {
      const flag = this.$data.isreadfenyeall;
      // TO DO
      // eslint-disable-next-line eqeqeq
      if (flag == true) {
        console.log("newVal:", newVal);
        console.log("oldVal:", oldVal);
        const len = newVal.length;
        this.$data.pageTotal =
          len % 10 === 0 ? Math.floor(len / 10) : Math.floor(len / 10) + 1;
      }
    },
    subscribeGuideList: function (newVal, oldVal) {
      const flag = this.$data.isreadfenyezn;
      // TO DO
      // eslint-disable-next-line eqeqeq
      if (flag == true) {
        console.log("newVal:", newVal);
        console.log("oldVal:", oldVal);
        const len = newVal.length;
        this.$data.pageTotalzn =
          len % 10 === 0 ? Math.floor(len / 10) : Math.floor(len / 10) + 1;
      }
    },
    subscribeTenderList: function (newVal, oldVal) {
      const flag = this.$data.isreadfenyezb;
      // TO DO
      // eslint-disable-next-line eqeqeq
      if (flag == true) {
        console.log("newVal:", newVal);
        console.log("oldVal:", oldVal);
        const len = newVal.length;
        this.$data.pageTotalzb =
          len % 10 === 0 ? Math.floor(len / 10) : Math.floor(len / 10) + 1;
      }
    },
  },
  methods: {
    onTipBoxClose() {
      this.showSendVipTip = false;
      this.$nextTick(()=> {
        window.location.reload()
      })
    },
    alertSendTip() {
      this.showSendVipTip = true;
    },
    navxuanzhong() {
      localStorage.setItem("navActive", 6);
      this.$store.commit("setnavActive", 6);
    },
    //同行再看
    getPeerReview() {
      const that = this;
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      formData.append("user_id", this.$store.state.userid); //添加文件对象 ，data中设置的
      axios({
        url: appurl + "PeerReview",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
          console.log(res);
          that.tonghang = res.data.resultList;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // 格式化日期格式
    formateDateList(arr) {
      arr.forEach((element) => {
        const datenum = Date.parse(new Date());
        if (element.PROJECT_DATE !== null) {
          const startnum = element.PROJECT_DATE * 1000;
          const endnum = element.PROJECT_DATE_END * 1000;
          if (datenum < startnum) {
            element.statusfordate = "未开始";
          } else if (datenum > endnum) {
            element.statusfordate = "已截止";
          } else {
            element.statusfordate = "进行中";
          }
          // eslint-disable-next-line eqeqeq
          if (element.PROJECT_DATE == false) {
            element.dateformatestart = "——";
          } else {
            element.dateformatestart = formatDate(
              parseInt(element.PROJECT_DATE)
            ).trim();
          }
          // eslint-disable-next-line eqeqeq
          if (element.PROJECT_DATE_END == false) {
            element.dateformateend = "——";
          } else {
            element.dateformateend = formatDate(
              parseInt(element.PROJECT_DATE_END)
            ).trim();
          }
        }
        if (
          element.TENDER_RELEASE_TIME &&
          element.TENDER_RELEASE_TIME !== null
        ) {
          const startnum = element.TENDER_RELEASE_TIME * 1000;
          const endnum = element.TENDER_END_TIME * 1000;
          if (datenum < startnum) {
            element.statusfordate = "未开始";
          } else if (datenum > endnum) {
            element.statusfordate = "已截止";
          } else {
            element.statusfordate = "进行中";
          }
          // eslint-disable-next-line eqeqeq
          if (element.TENDER_END_TIME == false) {
            element.dateformateend = "——";
          } else {
            element.dateformateend = formatDate(
              parseInt(element.TENDER_END_TIME)
            ).trim();
          }
          // eslint-disable-next-line eqeqeq
          if (element.TENDER_RELEASE_TIME == false) {
            element.dateformatestart = "——";
          } else {
            element.dateformatestart = formatDate(
              parseInt(element.TENDER_RELEASE_TIME)
            ).trim();
          }
        }
      });
    },
    // 时间转换成显示时间
   getTimeToShow(pushTime) {
      const nowtime = new Date();
      const time = nowtime - parseInt(pushTime * 1000);
      if (time < 60000) {
        return "刚刚";
      } else if (time > 60000 && time < 3600000) {
        return Math.floor(time / 60000) + "分钟前";
      } else if (time > 3600000 && time < 86400000) {
        return Math.floor(time / 3600000) + "小时前";
      }else if (time > 86400000 && time < 2592000000) {
        return Math.floor(time / 86400000) + '天前'
      }else if (time > 2592000000 && time < 31104000000) {
        return Math.floor(time / 2592000000) + '月前'
      }else{
        return Math.floor(time / 31104000000) + '年前'
      }
    },
    // 将数据转换成对象数组
    datatoarr(arr) {
      const arr1 = [];
      arr.forEach((element) => {
        element.forEach((e) => {
          arr1.push(e);
        });
      });
      return arr1;
    },
    // 给对象数组的每一项添加type类型标识
    addtype(arr, type) {
      const that = this;
      const arr1 = [];
      if (type === 1) {
        arr.forEach((element) => {
          element.type = type;
          element.times = that.times(Date.parse(element.PROJECT_DATE_END))
          element.showtimetext = that.getTimeToShow(
            parseInt(element.PROJECT_DATE)
          );
          if (element.PROJECT_CONTENT_KEYWORDS) {
            element.tagsArray = element.PROJECT_CONTENT_KEYWORDS.split(";");
          }

          arr1.push(element);
        });
      } else if (type === 2) {

        arr.forEach((element) => {
          element.type = type;
          element.times = that.times(Date.parse(element.PROJECT_DATE_END))
          element.showtimetext = that.getTimeToShow(
            parseInt(element.TENDER_RELEASE_TIME)
          );
          if (element.TENDER_KEYWORDS) {
            element.tagsArray = element.TENDER_KEYWORDS.split(";");
          }

          arr1.push(element);
        });
      }

      that.formateDateList(arr1);
      that.xialamoren()
      console.log("添加type后", arr1);
      return arr1;
    },
    //一级科学id
    z_zhuanye1(s_zhuanye1) {
      const that = this;
      const params7 = {
        INDUSTRY_ID: s_zhuanye1,
      };
      getHsjdiscipline(params7).then(
        (res) => {
          console.log("一级学科返回", res);
          if(that.zhuanye3){
            for (let i = 0; i < res.length; i++) {
              if(res[i].discipline_id == that.zhuanye3){
               return that.zhuanye2 = res[i].discipline
              }
            }
          }else{
            that.zhuanye2 = res[0].discipline;
          }
          // that.zhuanye21 = res[0].discipline_id;
          that.rmnav2 = res;
        },
        (err) => console.log("一级学科获取失败", err)
      );
    },
    z_zhuanye2(s_zhuanye2){
      this.zhuanye21 = s_zhuanye2
    },
    //完善提交
    z_tijiao() {
      const that = this;
      const params8 = {
        user_id: this.$store.state.userid,
        job: that.danwei,
        discipline_id: that.zhuanye21,
        key_word1: that.keywords1,
        key_word2: that.keywords2,
        key_word3: that.keywords3,
      };
      getHsjperfect(params8).then(
        (res) => {
          console.log("完善提交返回", res);
          that.wanshan1 = false;
          that.wanshan2 = true;
          this.getData();
        },
        (err) => console.log("完善提交失败", err)
      );
    },
    //完善打开
    z_open() {
      const that = this;
      that.wanshan1 = true;
    },
    //完善关闭
    z_close() {
      const that = this;
      that.wanshan1 = false;
	  that.viptc = false;
    },
    z_close1() {
      const that = this;
      that.wanshan3 = false;
    },
    z_getorganization_list_count() {
      // 获取我订阅的机构列表
      const params11 = {
        user_id: this.$store.state.userid,
      };
      getorganization_list_count(params11).then(
        (res) => {
          console.log("订阅的机构返回", res);
          this.$data.z_keywords1 = res;
        },
        (err) => console.log("获取列表失败", err)
      );
    },
    times(t){
      const timeme = new Date();
      const nowtime = Date.parse(new Date());
      const time = nowtime - t;
      return time
    },
    // 获取数据
    getData() {
      const that = this;
	  that.loading = true;
      let arrtoall = [];
      // 获取订阅列表
      const id = this.$store.state.userid;
      const params1 = {
        id: id,
        type: 1,
      };
      //  relatedProject(params4).then((res) => {});
      // subscribelist(params1).then(
      const axios = require("axios");
      const formData = new FormData(); //FormData对象，添加参数只能通过append('key', value)的形式添加
      const appurl = "https://data.keyanpro.com/keyanpro3/";
      if(id == ""){
        id = 0
      }
      formData.append("user_id", id); //添加文件对象 ，data中设置的
      axios({
        url: appurl + "RecommendedByRegisterUser",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
           console.log("申报订阅列表", res);
          const arrtest = res.data.resultList;
          that.$data.subscribeGuideList = that.addtype(arrtest, 1);
          that.$data.subscribeGuideListbeifen = that.addtype(arrtest, 1);
          console.log("申报列表备份", that.$data.subscribeGuideListbeifen);
          arrtoall = arrtoall.concat(that.addtype(arrtest, 1));
          console.log(that.$data.subscribeGuideList);
          // that.$data.subscribeAllList = res.data.resultList;
          // eslint-disable-next-line no-unused-expressions
          if (that.$data.subscribeGuideList.length !== 0) {
            that.$data.recommendSubscription =
              that.$data.subscribeGuideList[
                Math.floor(Math.random() * that.$data.subscribeGuideList.length)
              ].tagsArray;
          }
          const params2 = {
            id: id,
            type: 2,
          };
        });
          axios({
        url: appurl + "SubscribeProjectList",
        method: "post",
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (res) {
			that.loading = false
           console.log("申报订阅列表", res);
           const arrtest = res.data.resultList;
              that.$data.subscribeTenderList = that.addtype(arrtest, 2);
              console.log("招标订阅列表添加字段后", that.addtype(arrtest, 2));
              that.$data.subscribeTenderListbeifen = that.addtype(arrtest, 2);
              arrtoall = arrtoall.concat(that.addtype(arrtest, 2));
              that.$data.subscribeAllList = arrtoall;
              that.$data.subscribeAllListbeifen = arrtoall;
        });
      //   },
      //   (err) => {
      //     console.log("申报列表请求错误", err);
      //   }
      // );

      // 获取我订阅的机构列表
      const params3 = {
        user_id: id,
      };
      organizationllist(params3).then(
        (res) => {
          console.log("订阅的机构返回", res);
          that.$data.myorganizationllist = res;
          that.z_getorganization_list_count();
          if (res == "") {
            that.z_tuijian1 = true;
            that.z_tuijian2 = false;
          }
        },
        (err) => console.log("获取列表失败", err)
      );
      // 获取我订阅的标签列表
      const params4 = {
        user_id: id,
      };
      listSet(params4).then((res) => {
        if (res && res.data && res.data !== 404) {
          that.$data.mySubscribeTags = res.data;
          console.log("我订阅的标签", res.data);
          that.$data.z_keywords = res.keywords_num;
        }
      });
      // 获取我收藏的文章
      const params5 = {
        type: 4,
        user_id: id,
      };
      collectList(params5).then((res) => {
        that.$data.mySubscribeArticle = res;
      });
      // 获取区域范围列表
      this.getarealist();
      //用户信息
      const params6 = {
        user_id: id,
      };
      organizationluser(params6).then(
        (res) => {
          that.zhuanye3 = res.discipline
          console.log("用户信息返回", res);
          that.zimu = res.INDUSTRY;
          if(res.industry){
            that.zhuanye1 = that.rmnav1[res.industry-1].INDUSTRY_NAME
          }
          this.fanhuiqingqiu();
          if (
            res.job == "" ||
            res.discipline == "" ||
            res.job == "null" ||
            res.discipline == "null" ||
            res.job == null ||
            res.discipline == null
          ) {
            that.wanshan = true;
            that.wanshan2 = false;
            that.wanshan3 = true;
            that.wanshan4 = 5;
            that.z_zhuanye1(res.INDUSTRY)
          } else {
            that.wanshan4 = 10;
          }
          if (res.user_email != "") {
            that.z_email = res.user_email;
          } else {
          }
          if (res.email_subscribe == 1) {
            that.youxiang = 1;
          } else {
            that.youxiang = 0;
          }
          that.danwei = res.job;
          that.subscribelistlen = res.subscribe
          if(res.subscribe.length > 0){
            that.subscribelistlen1 = res.subscribe[0].keywords
            that.keywords1 = res.subscribe[0].keywords;
            if(res.subscribe.length > 1){
              that.subscribelistlen2 = res.subscribe[1].keywords
              that.keywords1 = res.subscribe[0].keywords;
              that.keywords2 = res.subscribe[1].keywords;
              if(res.subscribe.length > 2){
                that.subscribelistlen3 = res.subscribe[2].keywords
                that.keywords1 = res.subscribe[0].keywords;
                that.keywords2 = res.subscribe[1].keywords;
                that.keywords3 = res.subscribe[2].keywords
              }
            }
          }
        },
        (err) => console.log("获取列表失败", err)
      );
      //最新指南/学科门类
      getHsjindustry_new().then(
        (res) => {
          console.log("最新指南/学科门类", res);
          that.rmnav1 = res;
        },
        (err) => {
          console.log("最新指南/学科门类报错", err);
        }
      );
    },
    // 分页
    // 当前页
    curPage(i) {
      document.documentElement.scrollTop = 0;
      if (i === "...") return;
      this.pageNo = i;
      this.$data.subscribeTenderList = this.$data.subscribeAllListbeifen.slice(
        (i - 1) * 10,
        (i - 1) * 10 + 10
      );
      this.$data.isreadfenyeall = false;
    },
    // 指南
    curPagezn(i) {
		if (this.$store.state.ispro == "false") {
		    this.viptc = true;
			return;
		}
      document.documentElement.scrollTop = 0;
      if (i === "...") return;
      this.pageNozn = i;
      this.$data.subscribeGuideList = this.$data.subscribeGuideListbeifen.slice(
        (i - 1) * 10,
        (i - 1) * 10 + 10
      );
      this.$data.isreadfenyezn = false;
    },
    // 招标
    curPagezb(i) {
		if (this.$store.state.ispro == "false") {
		    this.viptc = true;
			return;
		}
      document.documentElement.scrollTop = 0;
      if (i === "...") return;
      this.pageNozb = i;
      this.$data.subscribeTenderList =
        this.$data.subscribeTenderListbeifen.slice(
          (i - 1) * 10,
          (i - 1) * 10 + 10
        );
      this.$data.isreadfenyezb = false;
    },
    // 下一页
    nextPage() {
      document.documentElement.scrollTop = 0;
      if (this.pageNo < this.pageTotal) {
        this.pageNo = Number(this.pageNo) + 1;
        const i = this.pageNo;
        this.$data.subscribeTenderList = this.$data.subscribeAllListbeifen.slice(
          (i - 1) * 10,
          (i - 1) * 10 + 10
        );
        this.$data.isreadfenyeall = false;
        // this.search()
      }
    },
    // 指南
    nextPagezn() {
      if (this.pageNozn < this.pageTotalzn) {
		document.documentElement.scrollTop = 0;
        this.pageNozn = Number(this.pageNozn) + 1;
        const i = this.pageNozn;
        this.$data.subscribeGuideList =
          this.$data.subscribeGuideListbeifen.slice(
            (i - 1) * 10,
            (i - 1) * 10 + 10
          );
        this.$data.isreadfenyezn = false;
        // this.search()
      }
    },
    nextPagezb() {
		if (this.$store.state.ispro == "false") {
		    this.viptc = true;
			return;
		}
      document.documentElement.scrollTop = 0;
      if (this.pageNozb < this.pageTotalzb) {
        this.pageNozb = Number(this.pageNozb) + 1;
        const i = this.pageNozb;
        this.$data.subscribeTenderList =
          this.$data.subscribeTenderListbeifen.slice(
            (i - 1) * 10,
            (i - 1) * 10 + 10
          );
        this.$data.isreadfenyezb = false;
        // this.search()
      }
    },
    // 招标

    // 上一页
    prePage() {
      document.documentElement.scrollTop = 0;
      if (this.pageNo > 1) {
        this.pageNo = Number(this.pageNo) - 1;
        const i = this.pageNo;
        this.$data.subscribeTenderList = this.$data.subscribeAllListbeifen.slice(
          (i - 1) * 10,
          (i - 1) * 10 + 10
        );
        this.$data.isreadfenyeall = false;
        // this.search()
        // this.paginationByNo(--this.pageNo, this.pageSize)
      }
    },
    // 指南
    prePagezn() {
      document.documentElement.scrollTop = 0;
      if (this.pageNozn > 1) {
        this.pageNozn = Number(this.pageNozn) - 1;
        const i = this.pageNozn;
        this.$data.subscribeGuideList =
          this.$data.subscribeGuideListbeifen.slice(
            (i - 1) * 10,
            (i - 1) * 10 + 10
          );
        this.$data.isreadfenyezn = false;
        // this.search()
        // this.paginationByNo(--this.pageNo, this.pageSize)
      }
    },
    // 招标
    prePagezb() {
		if (this.$store.state.ispro == "false") {
		    this.viptc = true;
			return;
		}
      document.documentElement.scrollTop = 0;
      if (this.pageNozb > 1) {
        this.pageNozb = Number(this.pageNozb) - 1;
        const i = this.pageNozb;
        this.$data.subscribeTenderList =
          this.$data.subscribeTenderListbeifen.slice(
            (i - 1) * 10,
            (i - 1) * 10 + 10
          );
        this.$data.isreadfenyezb = false;
        // this.search()
        // this.paginationByNo(--this.pageNo, this.pageSize)
      }
    },
    fanhuiqingqiu() {
      const that = this;
      const params8 = {
        INDUSTRY_ID: that.zimu,
      };
      getHsjdiscipline(params8).then(
        (res) => {
          console.log("一级学科返回", res);
          // that.zhuanye2 = res[0].discipline;
          // that.zhuanye21 = res[0].discipline_id;
        },
        (err) => console.log("一级学科获取失败", err)
      );
    },
    // 首页
    homePage() {
      this.pageNo = 1;
      this.page = 1;
      this.search();
    },
    // 末页
    endPage() {
      this.page = this.pageTotal;
      this.search();
    },
    // 分页跳转功能
    pageJump() {
      const val = this.$data.jumpInput;
      const total = this.$data.pageTotal;
      if (val !== "") {
        if (val > 0 && val <= total) {
          this.$data.pageNo = this.$data.jumpInput;
          this.paginationByNo(this.pageNo, this.pageSize);
        } else {
        }
      } else {
      }
    },

    // 科研人tab栏切换
    getsetswitchtab(){
      this.$data.switchtab = this.$store.state.switchtab;
    },
    changetag(index) {
      this.$data.switchtab = index;
      this.$store.commit("setswitchtab", index);
      if (index === 1) {
        this.$data.isshowsubscribeGuide = false;
        this.$data.isshowsubscribeTender = false;
        this.$data.isshowall = true;
      } else if (index === 2) {
        this.$data.isshowsubscribeGuide = true;
        this.$data.isshowsubscribeTender = false;
        this.$data.isshowall = false;
      } else if (index === 3) {
        this.$data.isshowsubscribeGuide = false;
        this.$data.isshowall = false;
        this.$data.isshowsubscribeTender = true;
      }
    },
    // 改变区域范围的展示与隐藏
    changeAreaShow() {
      this.isAreaShow = !this.isAreaShow;
    },
    // 改变项目状态的展示与隐藏
    changeStatusShow() {
      this.isStatusShow = !this.isStatusShow;
    },
    // 鼠标移入标签添加类名
    tagmouseenter(event) {
      event.currentTarget.classList.add("checked");
    },
    // 鼠标移出标签删除类名
    tagmouseleave(event) {
      event.currentTarget.classList.remove("checked");
    },
    handleClick() {
      alert("button click");
    },
    // 鼠标移入显示标签订阅按钮
    myorganizationenter(event, item) {
      // eslint-disable-next-line eqeqeq
      if (item.time_out == 1) {
        console.log(
          event.currentTarget.firstElementChild.firstElementChild
            .nextElementSibling.classList
        );
        event.currentTarget.firstElementChild.firstElementChild.nextElementSibling.classList.add(
          "show"
        );
      } else {
      }
    },
    // 鼠标移除隐藏订阅按钮
    myorganizationleave(event) {
      console.log(
        event.currentTarget.firstElementChild.firstElementChild
          .nextElementSibling.classList
      );
      event.currentTarget.firstElementChild.firstElementChild.nextElementSibling.classList.remove(
        "show"
      );
    },
    // 点击删除订阅机构
    delmyorganizationleav(ev, id) {
      let that = this
      const params = {
        id: id,
      };
      organizationDelete(params).then((res) => {
        ev.target.parentNode.parentNode.removeChild(ev.target.parentNode);
        that.z_getorganization_list_count()
        that.getData()
      });
      window._czc.push(["_trackEvent", "点击", "删除订阅机构"]);
    },
    // 鼠标移入显示标签订阅按钮
    subscribetagmouseenter(event, item) {
      // eslint-disable-next-line eqeqeq
      if (item.time_out == 1) {
        console.log(
          event.currentTarget.firstElementChild.nextElementSibling.classList
        );
        event.currentTarget.firstElementChild.nextElementSibling.classList.add(
          "show"
        );
      } else {
      }
    },
    // 鼠标移除隐藏订阅按钮
    subscribetagmouseleave(event) {
      console.log(
        event.currentTarget.firstElementChild.nextElementSibling.classList
      );
      event.currentTarget.firstElementChild.nextElementSibling.classList.remove(
        "show"
      );
    },
    // 点击删除我订阅的标签
    removesubscribetags(event, id) {
      let that = this
      const params = {
        id: id,
      };
      subscribedelete(params).then((res) => {
        event.target.parentNode.parentNode.removeChild(event.target.parentNode);
        // that.mySubscribeTags.splice(that.mySubscribeTags.indexOf(id),1);
        that.getData()
      });
      window._czc.push(["_trackEvent", "点击", "删除订阅标签"]);
    },
    // 鼠标移入显示标签订阅按钮
    recommendSubscriptionenter(event) {
      console.log(
        event.currentTarget.firstElementChild.nextElementSibling.classList
      );
      event.currentTarget.firstElementChild.nextElementSibling.classList.add(
        "show"
      );
    },
    // 鼠标移除隐藏订阅按钮
    recommendSubscriptionleave(event) {
      console.log(
        event.currentTarget.firstElementChild.nextElementSibling.classList
      );
      event.currentTarget.firstElementChild.nextElementSibling.classList.remove(
        "show"
      );
    },
    // 推荐订阅点击
    recommendSubscriptionclick(event) {
      const that = this;
      const id = that.$store.state.userid;
      const keywords = event.currentTarget.previousElementSibling.innerText;
      console.dir(keywords);
      const params = {
        user_id: id,
        keywords: keywords,
      };
      subscribeadd(params).then((res) => {
        console.log(res.data);
        if (res.code === 1) {
          that.$message({
            message: res.data.test,
            type: "success",
          });
        } else {
          that.$message({
            message: res.data.test,
            type: "warning",
          });
        }
      });
    },
    // 新增按钮点击事件
    addthetad() {
      this.$data.addtagisshow = false;
    },
    // 新增标签确定按钮点击事件
    confirmaddtag() {
      const that = this;
      const id = that.$store.state.userid;
      const keywords = that.$data.addtaginput;
      const type = that.$data.classalreadychecked;
      if (keywords === "") {
        if (keywords === "") {
          this.$message({
            message: "内容不能为空",
            type: "warning",
          });
        }
        // else if (type === "") {
        //   this.$message({
        //     message: "类别不能为空",
        //     type: "warning",
        //   });
        // }
      } else {
        that.$data.addtagisshow = true;
        console.dir(keywords);
        const params = {
          user_id: id,
          keywords: keywords,
          // type: type,
          type: 1,
        };
        subscribeadd(params).then((res) => {
          console.log(res.data);
          if (res.code === 1) {
            that.$message({
              message: res.data.test,
              type: "success",
            });
            that.$data.addtagisshow = true;
            that.$data.addtaginput = "";
            that.$data.classalreadychecked = "";
            that.$data.clsscheckedtext = "请选择标签类别";
            checkSubscribeSend({ user_id:  this.$store.state.userid}).then(result=> {
              if(result){
                this.alertSendTip()
              }
            })
          } else {
            that.$message({
              message: res.data.test,
              type: "warning",
            });
          }
        });
      }
    },
    // 状态下拉点击事件
    statustocheck(event) {
      this.$data.isStatusShow = false;
      console.log(event.target.innerText);
      this.$data.statusischecked = event.target.innerText;
    },
    // 获取地区列表数据
    getarealist() {
      const that = this;
      getSupportArea().then((res) => {
        that.$data.arealist = res.data;
        console.log("区域范围", res.data);
      });
    },
    // 点击区域选择按钮下拉
    areaitemClick(item) {
      this.$data.isreadfenyeall = true;
      this.$data.isreadfenyezn = true;
      this.$data.isreadfenyezb = true;
      const that = this;
      let arrtoall = [];
      this.$data.countryischecked = item.AREA_NAME;
      this.$data.isAreaShow = false;
      const id = this.$store.state.userid;
      let pid = item.AREA_ID;
      // eslint-disable-next-line eqeqeq
      if (pid == 100000) {
        pid = "";
      }
      const params1 = {
        id: id,
        type: 1,
        pro_id: pid,
      };
      subscribelist(params1).then(
        (res) => {
          console.log("申报订阅列表", res);
          const arrtest = that.datatoarr(res.data);
          that.$data.subscribeGuideList = that.addtype(arrtest, 1);
          that.$data.subscribeGuideListbeifen = that.addtype(arrtest, 1);
          arrtoall = arrtoall.concat(that.addtype(arrtest, 1));
          console.log(that.$data.subscribeGuideList);
          // eslint-disable-next-line no-unused-expressions
          if (that.$data.subscribeGuideList.length !== 0) {
            that.$data.recommendSubscription =
              that.$data.subscribeGuideList[
                Math.floor(Math.random() * that.$data.subscribeGuideList.length)
              ].tagsArray;
          }
          const params2 = {
            id: id,
            type: 2,
            pro_id: pid,
          };
          subscribelist(params2).then(
            (res) => {
              console.log("招标订阅列表", res);
              const arrtest = that.datatoarr(res.data);
              that.$data.subscribeTenderList = that.addtype(arrtest, 2);
              that.$data.subscribeTenderListbeifen = that.addtype(arrtest, 2);
              arrtoall = arrtoall.concat(that.addtype(arrtest, 2));
              that.$data.subscribeAllList = arrtoall;
              that.$data.subscribeAllListbeifen = arrtoall;
            },
            (err) => {
              console.log("招标列表请求错误", err);
            }
          );
        },
        (err) => {
          console.log("申报列表请求错误", err);
        }
      );
    },
    // 选择标签状态
    classchecked(item) {
      this.$data.clsscheckedtext = item.name;
      this.$data.classalreadychecked = item.id;
    },
    // 点击状态下拉
    xialamoren(){
        const arr = [];
        const arr1 = [];
        this.$data.subscribeGuideListbeifen.forEach((ele) => {
          // eslint-disable-next-line eqeqeq
            arr.push(ele);
        });
        this.$data.subscribeTenderListbeifen.forEach((element) => {
            arr1.push(element);
        });
        this.$data.subscribeGuideList = arr;
        this.$data.subscribeTenderList = arr1;
        this.$data.subscribeAllList = arr1.concat(arr);
    },
    statusItemClick(item) {
      console.log("指南", this.$data.subscribeGuideList);
      console.log("指南备份", this.$data.subscribeGuideListbeifen);
      console.log("招标", this.$data.subscribeTenderList);
      console.log("招标备份", this.$data.subscribeGuideListbeifen);
      if (item === 0) {
        this.$data.subscribeGuideList = this.$data.subscribeGuideListbeifen;
        this.$data.subscribeTenderList = this.$data.subscribeTenderListbeifen;
        this.$data.subscribeAllList = this.$data.subscribeAllListbeifen;
        // const arr = [];
        // const arr1 = [];
        // this.$data.subscribeGuideListbeifen.forEach((ele) => {
        //   // eslint-disable-next-line eqeqeq
        //     arr.push(ele);
        // });
        // this.$data.subscribeTenderListbeifen.forEach((element) => {
        //     arr1.push(element);
        // });
        // this.$data.subscribeGuideList = arr;
        // this.$data.subscribeTenderList = arr1;
        // this.$data.subscribeAllList = arr1.concat(arr);
      }
      else if (item === 1) {
        const arr = [];
        const arr1 = [];
        this.$data.subscribeGuideListbeifen.forEach((ele) => {
          // eslint-disable-next-line eqeqeq
          if (ele.statusfordate == "未开始") {
            arr.push(ele);
          }
        });
        this.$data.subscribeTenderListbeifen.forEach((element) => {
          if (element.statusfordate === "未开始") {
            arr1.push(element);
          }
        });
        this.$data.subscribeGuideList = arr;
        this.$data.subscribeTenderList = arr1;
        this.$data.subscribeAllList = arr1.concat(arr);
      } else if (item === 2) {
        const arr = [];
        const arr1 = [];
        this.$data.subscribeGuideListbeifen.forEach((ele) => {
          // eslint-disable-next-line eqeqeq
          if (ele.statusfordate == "进行中") {
            arr.push(ele);
          }
        });
        this.$data.subscribeTenderListbeifen.forEach((element) => {
          if (element.statusfordate === "进行中") {
            arr1.push(element);
          }
        });
        this.$data.subscribeGuideList = arr;
        this.$data.subscribeTenderList = arr1;
        this.$data.subscribeAllList = arr1.concat(arr);
      } else if (item === 3) {
        const arr = [];
        const arr1 = [];
        this.$data.subscribeGuideListbeifen.forEach((ele) => {
          // eslint-disable-next-line eqeqeq
          if (ele.statusfordate == "已截止") {
            arr.push(ele);
          }
        });
        this.$data.subscribeTenderListbeifen.forEach((element) => {
          if (element.statusfordate === "已截止") {
            arr1.push(element);
          }
        });
        this.$data.subscribeGuideList = arr;
        this.$data.subscribeTenderList = arr1;
        this.$data.subscribeAllList = arr1.concat(arr);
      }
    },
    getPageNo() {
      this.page = this.$store.state.pageNo;
      console.log("11111111111", this.page);
    },
    // 跳转到招标详情页
    toNewTenderDetail(item) {
      localStorage.setItem("navActive", 3);
      this.$store.commit("setnavActive", 3);
      this.$store.commit("setpageNo", this.page);
      const id = item.IN_PROJECT_GOV_ID;
      const uid = this.$store.state.userid;
      const params = {
        title_id: id,
        user_id: uid,
        // type: 1
      };
      // this.$router.push({
      //   path: `reportingGuidelinesDetails${id}`,
      // });
      Addhistoricalrecordzc(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      let md = item.PROJECT_GOV_MD5;
      if (md === null) {
        md = "";
      }
      // this.$router.push({
      //   name: "reportingGuidelinesDetails",
      //   params: {
      //     id: id,
      //   },
      //   query: {
      //     PROJECT_GOV_MD5: md,
      //   },
      // });
      // window._czc.push(["_trackEvent", "点击", "跳转到政采招标详情页"]);
      window.open(`/pc/content/reportingGuidelinesDetails${id}?PROJECT_GOV_MD5=${md}`)
    },
    // 跳转到最新课题申报信息详情页
    toReportingGuideDetail(item) {
      localStorage.setItem("navActive", 2);
      this.$store.commit("setnavActive", 2);
      const id = item.IN_PROJECT_GOV_ID;
      const uid = this.$store.state.userid;
      const params = {
        title_id: id,
        user_id: uid,
        // type: 1
      };
      Addhistoricalrecord(params).then(
        (res) => {
          console.log("添加历史记录成功", res);
        },
        (err) => {
          console.log("添加历史记录异常", err);
        }
      );
      let md = item.PROJECT_GOV_MD5;
      if (md === null) {
        md = "";
      }
      // this.$router.push({
      //   name: "reportingGuidelinesDetails",
      //   params: {
      //     id: id,
      //   },
      //   query: {
      //     PROJECT_GOV_MD5: md,
      //   },
      // });
      // window._czc.push(["_trackEvent", "点击", "跳转到课题申报详情页"]);
      window.open(`/pc/content/reportingGuidelinesDetails${id}?PROJECT_GOV_MD5=${md}`, "_blank")
    },
    // 跳转到我关注的课题或项目页
    tomySubjectproject() {
      this.$router.push({
        path: "mySubjectproject",
      });
    },
    // 取消添加标签
    quxiaoaddtag() {
      this.$data.addtagisshow = true;
      this.$data.addtaginput = "";
      this.$data.classalreadychecked = "";
      this.$data.clsscheckedtext = "请选择标签类别";
    },
    // 跳转到我收藏的文章
    tomyCollectionarticles() {
      this.$router.push({
        path: "myCollectionarticles",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到我的收藏"]);
    },
    // 跳转到我近期浏览过的信息
    tomyRecentInformation() {
      this.$router.push({
        path: "myRecentInformation",
      });
      window._czc.push(["_trackEvent", "点击", "跳转到我近期浏览过的信息"]);
    },
    //邮箱提交
    tuisong() {
      const that = this;
      const params9 = {
        user_id: this.$store.state.userid,
        email: that.z_email,
      };
      getEmail(params9).then(
        (res) => {
          console.log("邮箱推送", res);
          alert("保存成功");
          checkSubscribeSend({ user_id:  this.$store.state.userid}).then(result=> {
            if(result){
              this.alertSendTip()
            }else{
              location.reload();
            }
          })

        },
        (err) => console.log("邮箱推送失败", err)
      );
    },
    //不在订阅
    tuisong1() {
      const that = this;
      const params10 = {
        user_id: this.$store.state.userid,
      };
      getCancel_email(params10).then(
        (res) => {
          console.log("不在订阅", res);
          alert("取消成功");
          location.reload();
        },
        (err) => console.log("不在订阅失败", err)
      );
    },
    z_tuijian4(item,$event){
      for (let i = 0; i<this.cheList.length; i++){
          if($event){
            return this.cheList.push(item.id)
          }else{
            if(this.cheList[i] == item.id){
              this.cheList.splice(this.cheList.indexOf(item.id),1);
          }
        }
      }
    },
    z_tuijian3() {
      const params12 = {
        user_id: this.$store.state.userid,
        organization_id: this.cheList,//.join(";")
      };
      organizationAddNew(params12).then(
        (res) => {
          console.log("成功", res);
          checkSubscribeSend({ user_id:  this.$store.state.userid}).then(result=> {
            if(result){
              this.alertSendTip()
            }else{
              location.reload();
            }
          })
        },
        (err) => {
          console.log("失败", err);
        }
      );
    },
	// 跳转分享页
	vip() {
	  this.$router.push({
	    path: "/pc/viproot/vip",
	  });
	  this.$store.commit("setgundong", true);
	},
	vip1() {
	  this.$router.push({
	    path: "/pc/viproot/vip",
	  });
	  this.$store.commit("setgundong", false);
	},
  },
};
</script>
<style lang="scss">
::-webkit-scrollbar {
  width: 9px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #5e068c;
  border-radius: 21px;
  width: 20px;
  height: 63px;
}
::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 7px;
}
.el-dropdown {
  vertical-align: top;
  color: #5e068c;
}
.el-dropdown + .el-dropdown {
  margin-left: 15px;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #faf7fb;
  color: #5e069a;
}
.el-icon-arrow-down {
  font-size: 12px;
}
.el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0px 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #ebeef5;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
}
.theinput-left {
  .el-button {
    padding: 7px 29px;
    font-size: 0.12rem;
    margin-top: 10px;
    background-color: #faf7fb;
    color: #5e068c;
    width: 161px;
  }
}
</style>

<style lang="scss" scoped>
.spanover {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.show {
  display: block !important;
}
// 版心
.typeArea {
  width: 1200px;
  margin: 0 auto;
}
// 翻转180度
.flip {
  transform: rotate(180deg);
}
// 鼠标移上变小手
.hovertohand {
  cursor: pointer;
}
.background {
  background-color: #f1f2f6;
}
.tagjinyong {
  width: 246px;
  height: 30px;
  background-color: rgba($color: #000000, $alpha: 0.2);
  position: absolute;
}
.header {
  background-color: #fff;
  border-top: 1px solid #f0f0f0;
  height: 64px;
  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    .header-left {
      height: 100%;
      display: flex;
      justify-content: left;
      align-items: center;
      .left-tag {
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 40px;
        .text {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: #666666;
        }
        .henxian {
          width: 22px;
          height: 3px;
          background-color: #5e068c;
          position: absolute;
          bottom: 0px;
          opacity: 0;
        }
      }
      .textchecked {
        .text {
          font-size: 14px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 24px;
          color: #5e068c;
        }
        .henxian {
          opacity: 1;
        }
      }
    }
    .header-right {
      position: relative;
      display: flex;
      justify-content: right;
      align-items: center;
      .xiala {
        height: 28px;
        background-color: #faf7fb;
        padding: 0px 14px;
        padding-right: 7px;
        border-radius: 4px;
      }
      .right-tag {
        margin-left: 18px;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        display: flex;
        align-items: center;
        .tag-text {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          line-height: 50px;
          color: #5e068c;
          margin-right: 6px;
        }
        .triangle {
          width: 0;
          height: 0;
          border-left: 4px solid transparent;
          border-right: 4px solid transparent;
          border-top: 4px solid #5e068c;
        }
      }
      .status-select {
        cursor: pointer;
        max-height: 300px;
        position: absolute;
        right: 3px;
        top: 37px;
        font-size: 0.15rem;
        font-family: Source Han Sans CN;
        font-weight: 400;
        background-color: #fff;
        .status-item {
          height: 27px;
          padding: 0px 10px;
          line-height: 27px;
        }
        .status-item:hover {
          background-color: #faf7fb;
        }
      }
      .area-select {
        height: 134px;
        overflow-y: scroll;
        background: #fff;
        cursor: pointer;
        max-height: 300px;
        position: absolute;
        right: 138px;
        top: 37px;
        font-size: 0.15rem;
        font-family: Source Han Sans CN;
        font-weight: 400;

        .area-item {
          text-align: center;
          height: 27px;
          padding: 0px 10px;
          line-height: 27px;
          width: 70px;
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .area-item:hover {
          background-color: #faf7fb;
        }
      }
    }
  }
}
.spacing {
  height: 64px;
}
.content {
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  .content-left {
    box-sizing: border-box;
    width: 840px;
    display: flex;
    flex-direction: column;
    .items {
      padding: 30px 26px;
      box-sizing: border-box;
      background-color: #fff;
      .content-item {
        display: flex;
        .item-tag {
          width: 45px;
          height: 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 11px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 400;
        }
        .keti {
          border: 1px solid #990263;
          color: #990263;
          width: 49px;
          height: 23px;
        }
        .zhaobiao {
          border: 1px solid #5e068c;
          color: #5e068c;
          width: 49px;
          height: 23px;
        }
        .re{
          width: 16px;
          height: 16px;
          background: linear-gradient(138deg, #FF9B9A 0%, #F44C41 100%);
          border-radius: 2px 2px 2px 2px;
          padding: 1px;
          opacity: 1;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          -webkit-text-stroke: 1px rgba(0,0,0,0);
          margin: 2px 6px 0 0;
        }
        .zhi{
          width: 16px;
          height: 16px;
          background: linear-gradient(138deg, #9F9F9F 0%, #757575 100%);
          border-radius: 2px 2px 2px 2px;
          padding: 1px;
          opacity: 1;
          font-size: 10px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 26px;
          -webkit-text-stroke: 1px rgba(0,0,0,0);
          margin: 2px 6px 0 0;
        }
        .jiezhi {
          border: 1px solid #707070;
          color: #707070;
          width: 49px;
          height: 23px;
        }
        .jinxing {
          border: 1px solid #990099;
          color: #990099;
          width: 49px;
          height: 23px;
        }
        &:last-child {
          .item-content {
            border-bottom: 0;
          }
        }
        .item-content {
          flex: 1;
          display: flex;
          flex-direction: column;
          margin-bottom: 34px;
          /* padding-bottom: 14px; */
          border-bottom: 1px solid #f0f0f0;
          .content-title {
            display: flex;
            cursor: pointer;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #282828;
            margin-bottom: 20px;
            .title-text {
              flex: 1;
              color: #000;
              // font-weight: bold;
              font-size: 20px;
            }
          }
          .content-info {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 25px;
            .info-item {
              display: flex;
              margin-right: 48px;
              margin-bottom: 12px;
              font-size: 15px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #666666;
              overflow: hidden;
              white-space: nowrap;
              .fbjg {
                display: block;
                width: 166px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }
            .long {
              width: 240px;
              max-width: 240px;
            }
          }
          .content-tag {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            .tag-item {
              padding:0 6px;
              height: 20px;
              background-color: #f5f6fa;
              margin-right: 10px;
              border-radius: 1px;
              margin-bottom: 10px;
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .checked {
              background-color: #5e068c;
              color: #fff;
            }
          }
        }
      }
    }
    .paging {
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;
      ul {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0px;
      }
      li {
        cursor: pointer;
        width: 50px;
        height: 50px;
        margin: 0px 4px;
        background-color: #fff;
        // border: 1px solid #b9b9b9;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4px;
        a {
          font-size: 15px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #282828;
        }
      }
      .active {
        background: #5e068c;
        border: 1px solid #5e068c;
        opacity: 1;
        a {
          color: #fff;
        }
      }
    }
  }
  .content-right {
    .right-item {
      margin-bottom: 20px;
      box-sizing: border-box;
      background-color: #fff;
      width: 339.5px;
      padding: 22px 29px;

      .item-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 17px;
        .left {
          display: flex;
          align-items: center;
        }
        .right {
          font-size: 12px;
          color: #999999;
        }
        .shuxian {
          width: 4px;
          height: 12px;
          border-radius: 2px;
          background-color: #5e068c;
          margin-right: 10px;
        }
        .text {
          font-size: 18px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }
        .tianjia {
          display: none;
        }
      }
      .item-content {
        margin-bottom: 9px;
        width: 100%;
        max-height: 100px;
        overflow: auto;
        &::-webkit-scrollbar-thumb {
          background: rgba(153, 153, 153, 0.5);
        }
        &::-webkit-scrollbar-track {
          background: transparent;
        }
        .tag-item {
          width: 100%;
          cursor: pointer;
          display: flex;
          overflow: hidden;
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: 0px 8px;
          margin-right: 9px;
          margin-bottom: 11px;
          &:hover {
            .text {
              color: #5e068c;
            }
          }
          .text {
            // max-width: 260px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #B0B0B0;
            margin-right: 6px;
          }
          .x {
            display: none;
          }
          .tianjia {
            display: none;
          }
        }
        .tag-no {
          cursor: no-drop;

          background-color: #efefef;
          .text {
            color: #1b1b1b;
          }
        }
      }
      .addtag {
        cursor: pointer;
        width: 90px;
        height: 30px;
        border: 1px solid #5e068c;
        border-radius: 2px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
        .jiahao {
          margin-right: 10px;
        }
        .addtag-text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #5e068c;
        }
      }
      .theinput {
        .theinput-left {
          // height: 69px;
          width: 177px;
          input {
            width: 162px;
          }
        }
        .theinput-right {
          margin-left: 6px;
          // height: 69px;
          display: flex;
          justify-content: space-between;
          align-items: flex-end;
        }
        display: flex;
        margin-bottom: 10px;
        align-items: center;
        input {
          color: #5e068c;
          outline: none;
          margin-right: 5px;
          max-width: 300px;
          height: 30px;
          padding-left: 5px;
          box-sizing: border-box;
          font-size: 14px;
          border: 1px solid #707070;
        }
        .confirm {
          cursor: pointer;
          width: 0.45rem;
          height: 20px;
          box-sizing: border-box;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 0.11rem;
          font-size: 0.12rem;
          font-family: Microsoft YaHei;
          font-weight: 400;
          border: 0.01rem solid #990263;
          color: #990263;
        }
        .quxiao {
          border: 0.01rem solid #707070;
          color: #707070;
        }
      }
      .item-prompt {
        display: flex;
        justify-content: left;
        align-items: center;
        .prompt-text {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 19px;
          color: #999999;
        }
      }
      // 我订阅的机构
      .item-jigou {
        display: flex;
        flex-direction: column;
        .jigou-tag {
          height: 30px;
          background-color: #faf7fb;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 9px 8px;
          box-sizing: border-box;
          margin-bottom: 9px;
          .tag-text {
            max-width: 200px;
            overflow: hidden;
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            line-height: 24px;
            color: #5e068c;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
          .x {
            cursor: pointer;
            display: none;
          }
        }
        .tag-no {
          cursor: no-drop;

          background-color: #efefef;
          .tag-text {
            color: #1b1b1b;
          }
        }
      }

      .prompts {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 22px;
        .prompts-left {
          display: flex;
          align-items: center;
          .prompt-icon {
            margin-right: 12px;
          }
          .prompt-text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #999999;
          }
        }
        .prompts-right {
          .tag-num {
            width: 30px;
            height: 30px;
            background-color: #f5f5f5;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 19px;
            color: #999999;
          }
        }
      }
    }
  }
  .nocollection {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.z_wanshan {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
  .dt {
    width: 1200px;
    height: 782px;
    background: #ffffff;
    border-radius: 4px;
    overflow: hidden;
    opacity: 1;
    display: flex;
    .left {
      width: 0;
      display: flex;
      flex-direction: column;
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      padding-top: 92px;
      padding-left: 92px;
      .title {
        font-size: 36px;
        color: #000;
        padding-bottom: 9px;
        margin-bottom: 50px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 35px;
          height: 4px;
          background: #5e068c;
        }
      }
      .ul {
        display: flex;
        margin-bottom: 50px;
        flex-flow: wrap;
        .li {
          margin-right: 95px;
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
      .li {
        display: flex;
        flex-direction: column;
        width: 290px;
        .font {
          height: 20px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #b9b9b9;
          font-weight: bold;
          span {
            color: #f00;
          }
        }
        .el-input {
          ::v-deep .el-input__inner {
            border: 0;
            border-bottom: 1px solid #b9b9b9;
            border-radius: 0;
          }
        }
        .el-select {
          ::v-deep .el-input__inner {
            border: 0;
            border-bottom: 1px solid #b9b9b9;
            border-radius: 0;
          }
        }
      }
      .anniu {
        display: flex;
        margin-top: 20px;
        .el-button {
          color: #5e068c;
          border-color: #5e068c;
          margin-left: 0;
          width: 160px;
          height: 60px;
          font-size:18px;
          &.active {
            background-color: #5e068c;
            color: #fff;
            margin-right: 30px;
          }
        }
      }
    }
    .right {
      width: 341px;
      height: 100%;
      position: relative;
      background: linear-gradient(180deg, #5e068c 0%, #2f0346 100%);
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
      position: relative;
      img {
        width: 396px;
        height: 384px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.z_youxiang {
  font-size: 14px;
  color: #4e4e4e;
  margin-bottom: 14px;
  line-height: 1.5;
}
.z_yx_input {
  display: flex;
  align-items: center;
  background: #fafafa;
  margin-bottom: 20px;
  img {
    margin: 0 9px;
    width: 19px;
  }
  .el-input {
    ::v-deep .el-input__inner {
      border: 0;
      border: 0;
      background: transparent;
    }
  }
}
.z_yx_anniu {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .anniu1 {
    background: #5e068c;
    color: #fff;
    width: 100%;
  }
  .anniu2 {
    width: calc(50% - 5px);
  }
}
.z_tonghang {
  padding: 0;
  width: 100%;
  li {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    h1 {
      font-size: 14px;
      color: #282828;
      margin: 0;
      margin-bottom: 10px;
      line-height: 1.5;
      font-weight: normal;
      cursor: pointer;
    }
    h2 {
      font-size: 14px;
      color: #aaaaaa;
      margin: 0;
      font-weight: normal;
    }
  }
}

.z_wanshan1 {
  width: 100%;
  height: 80px;
  background: #ffffff;
  box-shadow: 0px 3px 12px 1px rgba(137, 137, 137, 0.16);
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .left {
    font-size: 20px;
    color: #5e068c;
    margin-left: 20px;
    font-weight: bold;
  }
  .right {
    display: flex;
    align-items: center;
    .open {
      background: #5e068c;
      font-size: 14px;
      color: #fff;
    }
    .close {
      width: 20px;
      height: 20px;
      margin: 0 30px;
      cursor: pointer;
    }
  }
}

.z_wanshan2 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #878787;
  margin-bottom: 50px;
  .a {
    color: #5e068c;
    cursor: pointer;
  }
}

.z_tuijian {
  width: 100%;
  .title {
    font-size: 14px;
    color: #666666;
    margin-bottom: 20px;
  }
  ul {
    width: 100%;
    margin: 0;
    padding: 0;
    margin-bottom: 5px;
    el-checkbox-group{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      list-style-type: none;
      font-size: 14px;
      color: #5e068c;
      ::v-deep .el-checkbox__input.is-checked+.el-checkbox__label{color: #5e068c;}
      ::v-deep .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner{border-color: #5e068c;
        background: #5e068c;}
    }
    li {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      list-style-type: none;
      font-size: 14px;
      color: #5e068c;
      ::v-deep .el-radio__label {
        display: none;
      }
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        border-color: #5e068c;
        background: #5e068c;
      }
    }
  }
  .anniu {
    width: 100%;
    background: #5e068c;
    font-size: 14px;
    color: #fff;
  }
}
.z_loading {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: rgba(255,255,255,0.6);
}
.z_vip {
  background: rgba(4, 4, 4, 0.39);
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99;
  left: 0;
  top: 0;
  .dt {
    width: 780px;
    padding-top: 55px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.16);
    opacity: 1;
    overflow: hidden;
    border-radius: 20px;
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 50%;
    margin-top: -245px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: flex-end;
    img {
      width: 235px;
    }
    .h1 {
      margin-top: 20px;
      font-size: 26px;
      margin-bottom: 25px;
      font-weight: bold;
      color: #dc7200;
      span {
        font-size: 40px;
        color: #990263;
      }
    }
    .h2 {
      margin-bottom: 33px;
      font-size: 22px;
      font-weight: 400;
      line-height: 34px;
      text-align: center;
      color: #dc7200;
    }
    .anniu {
      width: 100%;
      display: flex;
      a {
        width: 50%;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 25px;
        justify-content: center;
        height: 116px;
        background: rgba(94, 6, 140, 1);
        color: #fff;
        h1 {
          font-size: 25px;
          color: #121212;
          display: flex;
          flex-direction: column;
          line-height: 1.5;
          position: relative;
          span {
            font-size: 14px;
            color: #4b4b4b;
          }
        }
        &:first-child {
          background: linear-gradient(90deg, #ffce86 0%, #ffbc76 100%);
          color: #121212;
        }
        img {
          width: 44px;
          margin-right: 15px;
        }
      }
    }
    .close {
      position: absolute;
      right: 15px;
      top: 15px;
      transform: rotate(45deg);
      color: #b5b5b5;
      font-size: 40px;
      cursor: pointer;
    }
  }
}
::v-deep .el-dialog{
  background-color: transparent;
}
.bg-send {
      position: relative;
			/*矩形 913*/
			opacity: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
			border-radius: 12px;
			background: linear-gradient(180deg, rgba(251, 230, 186, 1) 0%, rgba(255, 255, 255, 1) 40%) !important;
		}
		.btn-send {
			/*矩形 911*/
			display: flex;
			align-items: center;
			justify-content: center;
			width: 205px;
			height: 40px;
			font-size: 16px;
			color: #FFFFFF;
			text-align: center;
			border-radius: 21px;
			position: absolute;
			bottom: 32px;
			background: rgba(232, 153, 0, 1) !important;
		}
		.send-title{
			color: #E89900;
			font-size: 24px;
			font-weight: 600;
			margin-bottom: 16px;
      padding-top: 90px;
      text-align: center;
		}
		.send-desc {
			width: 260px;
			line-height: 1.5em;
			text-align: center;
			font-size: 1.05em;
      padding-top: 32px;
			padding-bottom: 110px;
		}
		.send-desc span {
			font-weight: 600;
			color: #990263;
		}
		.close-tr {
			position: absolute;
			top: 24px !important;
			right: 24px !important;
			width: 14px !important;
			height: 14px !important;
		}

		.send-res1{
      position: absolute;
			top: -45px !important;
			left: calc(50% - 75px) !important;
			width: 160px !important;
			height: 140px !important;
		}
</style>
